import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);



  const imageUrls = [
    'https://i.ibb.co/GTdncrF/cropped-1.jpg',
    'https://i.ibb.co/4dLjHKZ/cropped-2n.jpg',
    'https://i.ibb.co/F4fjPPL/cropped-3.jpg',
    'https://i.ibb.co/qDHKJy1/cropped-4.jpg',
    'https://i.ibb.co/qxGH4Nc/cropped-5.jpg',
    'https://i.ibb.co/Zx5PnYy/cropped-6-2.jpg',
    'https://i.ibb.co/GTdncrF/cropped-1.jpg',
    'https://i.ibb.co/4dLjHKZ/cropped-2n.jpg',
    'https://i.ibb.co/F4fjPPL/cropped-3.jpg',
    'https://i.ibb.co/qDHKJy1/cropped-4.jpg',
    'https://i.ibb.co/qxGH4Nc/cropped-5.jpg',
    'https://i.ibb.co/Zx5PnYy/cropped-6-2.jpg'

  ];

  return (

    <div className="container">
    <img 
      src="https://i.ibb.co/3p1X4Xc/svgexport-2-1-5.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/V3vs3dP/photo-de-profil-modified.png'/>
      <section>
          <div className='flexRow'>Sub Enchantress 👼  <div className='buttonStyling'>En Ligne</div></div>
          <div className='flexRow'>
            <span>
            Votre comptable préféré est "très proche de vous (10,4km)"{country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://mym.fans/Sub_enchantress" id="customButton2">
    Abonnement gratuit avec code "KDO"
    </a>

    <a href="https://mym.fans/Sub_enchantress" id="customButton">
    REJOINS MOI SUR MYM 💋
    </a>



    <p className="exclusiveContent">
    <span>
    Envie d'essayer un café gourmand en ville ? On pourrait partager un moment spécial 👆🏼
              </span>
    </p>
    <Analytics/>
  </div>

  );
}

export default App;
